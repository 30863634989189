import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const CookiesContent = ({cookies}: {cookies: Dispatch<SetStateAction<boolean>>}) => {

  const { t } = useTranslation();
    const { i18n } = useTranslation();


  const [isVisible, setIsVisible] = useState(true);
  const [shouldRender, setShouldRender] = useState(true);


  const handleChoice = (choice: "allow" | "refuse") => {

    if (choice === "allow") {
      cookies(true);
    }

    if (choice === "refuse") {
      cookies(false)
    }

    setIsVisible(false);
  };



  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <>
      <style>
        {`
          .cookie-consent {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            background-color: rgba(255, 255, 255, 0.53);
            backdrop-filter: blur(4px);
            border-top: 1px solidrgba(229, 231, 235, 0.46);
            animation: slideIn 0.3s ease-out;
            z-index: 5000;
          }
          .cookie-consent.hiding {
            animation: slideOut 0.3s ease-in forwards;
          }
          @keyframes slideIn {
            from {
              transform: translateY(100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
          @keyframes slideOut {
            from {
              transform: translateY(0);
              opacity: 1;
            }
            to {
              transform: translateY(100%);
              opacity: 0;
            }
          }
          .consent-container {
            max-width: 56rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            font-size: 0.875rem;
          }
          .consent-text {
            color: #4b5563;
          }
          .button-group {
            display: flex;
            gap: 0.75rem;
          }
          .button-refuse {
            padding: 0.5rem 1rem;
            color:rgb(148, 153, 161);
            background: none;
            border: none;
            cursor: pointer;
            transition: color 200ms ease;
          }
          .button-refuse:hover {
            color: #1f2937;
          }
          .button-allow {
            padding: 0.5rem 1rem;
            color: white;
            background-color:rgb(119, 167, 170);
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
            transition: background-color 200ms ease;
          }
          .button-allow:hover {
            background-color: rgb(98, 141, 144);
          }
          @media (min-width: 640px) {
            .consent-container {
              flex-direction: row;
            }
          }
        `}
      </style>

          {shouldRender && (
            <div className={`cookie-consent ${!isVisible ? "hiding" : ""}`}>
              <div className="consent-container">
                <p className="consent-text">
                  {t('cookies')}
                  
                </p>
                <div className="button-group">
                  <button
                    className="button-refuse"
                    onClick={() => handleChoice("refuse")}
                  >
                    {t('refuse')}
                  </button>
                  <button
                    className="button-allow"
                    onClick={() => handleChoice("allow")}
                  >
                    {t('allow')}
                  </button>
                </div>
              </div>
            </div>
          )}
    </>
  );
}
export default CookiesContent;