import { Container } from "react-bootstrap";
import SignupModal from "../organs/SignupModal";
import AppointmentModal from "../organs/AppointmentModal";
import Footer from "./Footer";
import { Element } from "react-scroll";
import ContactSection from "../organs/ContactSection";
import InfoSection from "../organs/InfoSection";
import AboutSection from "../organs/AboutSection";
import { useTranslation } from "react-i18next";
import Treatments from "../organs/Treatments";
import TeamSection from "../organs/TeamSection";

const Content = ({cookies}: {cookies: boolean}) => {
    const { t } = useTranslation();

    return (
      <div className="homeComplete">
            <div className="homeBackground">
                <Container>
                    <Element name="section1">
                        <div className="homeContent homeBackground" >
                            <div className="titleContainer">
                                <h2 className="display-5 titleContent">
                                    <span>
                                        {t("mainTitle")}
                                    </span>
                                </h2>
                                <h5 className="homeSubtitle">
                                    {t("mainSubtitle")}
                                </h5>
                            </div>
                        <div className="actionButtons">
                            <span className="signupButton signupButtonHome" id="signUpButtonHome">
                                <SignupModal animatedClass="animatedButton" cookies={cookies}/>
                            </span>
                            <span className="appointmentButtonHome">
                                <AppointmentModal animatedClass="animatedButtonDelay" cookies={cookies}/>
                            </span>
                                
                        </div>
                    </div>      
                </Element>
            </Container>
        </div>
            
        <div className="aboutPractice">
            <Container>
                <Element name="section2">
                    <AboutSection />
                </Element>
            </Container>
            <Container>
                <Element name="section2Sub">
                    <TeamSection/>
                </Element>
            </Container>
        </div>

        <div>
            <Container>
                <Element name="section3">
                    <Treatments/>
                </Element>
            </Container>
        </div>

        <div>
            <Element name="section4">
                <InfoSection/>
            </Element>    
        </div>

        <div>
            <Container>
                <Element name="section5">
                    <ContactSection cookies={cookies}/>
                </Element>
            </Container>
        </div>

        <Footer/>
      </div>
    );
  }
  
  export default Content;