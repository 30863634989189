import { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import CookiesContent from '../species/CookiesContent';
import emailjs from '@emailjs/browser';
import FeedbackSignUp from '../cells/FeedbackSignup';
import { service, profile, templateSign } from '../scripts/service';
import ReCAPTCHA from 'react-google-recaptcha';
import Loading from './Loading';

function SignupModal(props: any) {
  const animClass = props.animatedClass ? props.animatedClass : "";
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const recaptcha = useRef<any>();

  const handleClose = () => {
    setShow(false); 
    setValidated(false);
    setSuccess(false)
  };
  const handleShow = () => {
    setShow(true); 
    setSuccess(false)
  };

  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = document.getElementById('signUpForm') as HTMLFormElement;

    if (recaptcha && recaptcha.current) {
      const captchaValue = recaptcha.current.getValue()
      if (props.cookies && !captchaValue) {
          alert('Please verify the reCAPTCHA!')
      }
      if (!captchaValue) {
        event.stopPropagation();
        setValidated(true);
        setSuccess(false);
        return;
      }
    }
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      setSuccess(false);
      return;
    }
    if (form.checkValidity() === true) {
      setValidated(true);
      setLoading(true);
      emailjs.sendForm(service(), templateSign(), '#signUpForm', profile()).then(
          (response) => {
            setLoading(false);
            setSuccess(true);
          },
          (error) => {
            setLoading(false);
            alert('Server error: something went wrong.');
          },
      );
    }
  };

  return (
    <>
      <Button 
       aria-label={`${i18next.resolvedLanguage === 'en' ? "sign up" : "inschrijven"}`}
        className={props.type === 'secondary' ? 
        `${animClass} ${i18next.resolvedLanguage === 'en' ? "signUpButtonEN" : "signUpButtonNL"} {signUpButtonEN} secondaryActionButtonDark`:
        `${animClass} ${i18next.resolvedLanguage === 'en' ? "signUpButtonEN" : "signUpButtonNL"} signUpButtonWhite`} 
        onClick={handleShow}></Button>

      <Modal show={show} fullscreen onHide={handleClose} aria-hidden="true" tabIndex="-1" >
        <Modal.Header closeButton>
          <Modal.Title>{t("signUpModalTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loading/>}
          {!loading && success && <FeedbackSignUp/>}
          {!loading && !success && 
          <Form noValidate validated={validated} onSubmit={handleSubmit} id="signUpForm">
          <Form.Group className="mb-3" controlId="signupForm.Surname">
            <FloatingLabel label={t("surname")} className="mb-3">
                <Form.Control
                    type="text"
                    required
                    minLength={1}
                    autoFocus
                    placeholder='Jansen'
                    name="surname"
                />
                <Form.Control.Feedback type="invalid">
                 {t("provideSurname")}
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                {t("looksGood")}
                </Form.Control.Feedback>
                </FloatingLabel>
                
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Name">
                <FloatingLabel label={t("name")} className="mb-3">
                    <Form.Control
                        type="text"
                        required
                        minLength={1}
                        placeholder='Jan'
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("provideName")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Birthday">
                <FloatingLabel label={t("dateOfBirth")} className="mb-3">
                <Form.Control required
                placeholder={t("dateOfBirth")}
                    type="date"
                    name="birthday"/>
                    <Form.Control.Feedback type="invalid">
                    {t("provideBirthday")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Email">
                <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                        required
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("provideEmail")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Phone">
                 <FloatingLabel label={t("phone")} className="mb-3">
                    <Form.Control
                        type="text"
                        required
                        minLength={5}
                        placeholder={t("phone")}
                        name="phone"
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("providePhone")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="signupForm.Message"
            >
              <FloatingLabel label={t("importantMessage")} className="mb-3">
              <Form.Control as="textarea" rows={3} placeholder={t("importantMessage")} name="message"/>
                </FloatingLabel>
              
            </Form.Group>
            <p className="cookies">{t("byContinuingCookies")}<CookiesContent/> {t('policy')}.</p>
            {props.cookies &&  <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />}
          </Form>
          } 
        </Modal.Body>
        <Modal.Footer>
          <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "close" : "sluiten"}`} className="modalButtonClose" variant="outline" onClick={handleClose}>
            {t("close")}
          </Button>
          {!success && 
            <Button type="submit" 
          aria-label={`${i18next.resolvedLanguage === 'en' ? "sign up" : "inschrijven"}`} 
          className="modalButtonSignup" 
          variant="outline" 
          onClick={handleSubmit}>
            {t("signUp")}
          </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignupModal;