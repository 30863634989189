import React from 'react';
import './App.css';
import HeaderMenu from './components/species/HeaderMenu';
import Content from './components/species/Content';
import "./i18n";
import { useState} from 'react';
import CookiesContent from './components/organs/Cookies';

function App() {

  const [allowCookies, setAllowCookies] = useState<boolean>(true);

  return (
    <div className="App">
      <HeaderMenu/>
      <Content cookies={allowCookies}/>
      <CookiesContent cookies={setAllowCookies}/>
    </div>
  );
}

export default App;
