import { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CookiesContent from '../species/CookiesContent';
import emailjs from '@emailjs/browser';
import FeedbackAppointment from '../cells/FeedbackAppointment';
import { service, profile, templateApp } from '../scripts/service';
import ReCAPTCHA from 'react-google-recaptcha';
import Loading from './Loading';

function AppointmentModal(props: any) {

  const animClass = props.animatedClass ? props.animatedClass : "";

  const { t } = useTranslation();

  const recaptcha = useRef<any>();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setSuccess(false);
  };
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = document.getElementById('appointmentForm') as HTMLFormElement;

    if (recaptcha && recaptcha.current) {
      const captchaValue = recaptcha.current.getValue()
      if (props.cookies && !captchaValue) {
          alert('Please verify the reCAPTCHA!')
      }
      if (!captchaValue) {
        setSuccess(false);
        setValidated(true);
        event.stopPropagation();
        return;
      }
    }

    if (form.checkValidity() === false) {
      setSuccess(false);
      setValidated(true);
      event.stopPropagation();
      return;
    }
    if (form.checkValidity() !== false) {
      setValidated(true);
      setLoading(true);
      emailjs.sendForm(service(), templateApp(), '#appointmentForm', profile()).then(
          (response) => {
            setLoading(false);
            setSuccess(true);
          },
          (error) => {
            setLoading(false);
            alert('Server error: something went wrong.');
          },
      );
    }
  };

  return (
    <>
    
    <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "make an appointment" : "maak een afspraak"}`} className={`${animClass} ${i18next.resolvedLanguage === 'en' ? "appointmentButtonEN" : "appointmentButtonNL"}  signUpButtonOutline`}  onClick={handleShow}> </Button>
      
      <Modal show={show} fullscreen onHide={handleClose} aria-hidden="true" tabIndex="-2">
        <Modal.Header closeButton>
          <Modal.Title>{t("appointmentModalTitle")}  <br/> {t("appointmentModalSubtitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loading/>}
          {!loading && success && <FeedbackAppointment/>}
          {!loading && !success && 
          <Form noValidate validated={validated} onSubmit={handleSubmit} id="appointmentForm">
          <Form.Group className="mb-3" controlId="signupForm.Surname">
            <FloatingLabel label={t("surname")} className="mb-3">
                <Form.Control
                    type="text"
                    autoFocus
                    required
                    placeholder='Jansen'
                    name="surname"
                />
                <Form.Control.Feedback type="invalid">
                    {t("provideSurname")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Name">
                <FloatingLabel label={t("name")} className="mb-3">
                    <Form.Control
                        type="text"
                        required
                        placeholder='Jan'
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("provideName")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="appointmentModal.Email">
              <FloatingLabel label="Email" className="mb-3">
              <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  required
                  name="email"
                />
                <Form.Control.Feedback type="invalid">
                    {t("provideEmail")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
            </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signupForm.Phone">
                 <FloatingLabel label={t("phone")} className="mb-3">
                    <Form.Control
                        type="text"
                        required
                        placeholder={t("phone")}
                        name="phone"
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("providePhone")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <p className="cookies">{t('appointmentInstructions')}</p>
            <Form.Group
              className="mb-3"
              controlId="appointmentModal.Message"
            >
              <FloatingLabel label={t("preferedDates")} className="mb-3">
                <Form.Control as="textarea" rows={3} required placeholder={t("preferedDates")} name="dates-times"/>
                <Form.Control.Feedback type="invalid">
                    {t("provideDates")}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>
                    {t("looksGood")}
                    </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <p className="cookies">{t("byContinuingCookies")} <CookiesContent/> {t('policy')}.</p>
            {props.cookies && <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />}
          </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button aria-label={`${i18next.resolvedLanguage === 'en' ? "close" : "sluiten"}`} className="modalButtonClose" variant="outline" onClick={handleClose}>
            {t('close')}
          </Button>
          {!success && 
            <Button type="submit" aria-label={`${i18next.resolvedLanguage === 'en' ? "send message" : "stuur bericht"}`} className="modalButtonSignup" variant="outline" onClick={handleSubmit}>
              {t('sendMessage')}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppointmentModal;